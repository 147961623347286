import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const customerTagList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'customertag/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get customer tag err : ', err)

      return []
    })

  return response
}

const addCustomerTag = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'customertag',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add customer tag err : ', err)

      return {}
    })

  return response
}

const udpateCustomerTag = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'customertag',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('update customer tag err : ', err)

      return {}
    })

  return response
}

const deleteCustomerTag = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'customertag',
      param: id,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('delete customer tag err : ', err)

      return {}
    })

  return response
}

export default {
  customerTagList,
  addCustomerTag,
  udpateCustomerTag,
  deleteCustomerTag,
}
