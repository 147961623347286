<template>
  <div>
    <v-dialog
      v-model="isShowDetailTag"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('Customer') }} <v-spacer></v-spacer>
          <v-btn
            color="error"
            icon
            @click="$emit('update:is-show-detail-tag', false)"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  #
                </th>
                <th class="text-left">
                  {{ $t("customer_code") }}
                </th>
                <th class="text-left">
                  {{ $t("customer") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="customerListLocal.length > 0">
              <tr
                v-for="(cus, index) in customerListLocal"
                :key="index"
              >
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  <router-link
                    class="font-weight-medium text-decoration-none"
                    :to="{ name: 'DetailCustomer', params: { id: cus.customer_id_pri } }"
                  >
                    {{ cus.customer_id }}
                  </router-link>
                </td>
                <td
                  class="text-left"
                >
                  {{ cus.customer_name }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'

export default {
  model: {
    prop: 'isShowDetailTag',
    event: 'update:is-show-detail-tag',
  },
  props: {
    isShowDetailTag: {
      type: Boolean,
      default: false,
    },
    customerList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const customerListLocal = ref([])

    watch(() => props.isShowDetailTag, newVal => {
      if (newVal) {
        customerListLocal.value = JSON.parse(JSON.stringify(props.customerList))
      }
    })

    return {
      customerListLocal,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
