import customerTag from '@/api/customer/customerTag'
import shop from '@/api/shop'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useCustomerTag() {
  const loadingDelete = ref(false)
  const searchQuery = ref('')
  const dataTableList = ref([])
  const dataTableList2 = ref([])
  const totalDataTableList = ref(0)
  const customer_tag_id = ref('')
  const shop_name = ref('')
  const isDeleteCustomerTag = ref(false)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const tableColumns2 = ref([
    {
      text: '#',
      value: 'customer_tag_id',
      width: '50',
      align: 'center',
    },
    { text: i18n.t('tag'), value: 'customer_tag_name', align: 'center' },
    { text: i18n.t('amount_customers'), value: 'customer_tag_count', align: 'center' },
  ])
  const tableColumns = [
    {
      text: '#',
      value: 'customer_tag_id',
      width: '50',
      align: 'center',
    },
    { text: i18n.t('tag'), value: 'customer_tag_name', align: 'center' },
    { text: i18n.t('amount_customers'), value: 'customer_tag_count', align: 'center' },
    { text: i18n.t('option'), value: 'actions', align: 'center' },
  ]
  const loading = ref(false)
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const { customerTagList, deleteCustomerTag } = customerTag
  const { shop: getShop } = shop
  getShop().then(res => {
    shop_name.value = res.shop_name
  })

  const fetchDataTable = () => {
    loading.value = true
    const body = {
      searchtext: searchQuery.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }

    customerTagList(body).then(res => {
      const { count, data, segment, clinicpro, clinicsite, count_of_page } = res
      dataTableList2.value = [clinicpro, clinicsite]
      dataTableList.value = data
      totalPage.value = count_of_page
      totalDataTableList.value = count
      segmentId.value = segment
      loading.value = false
    })
  }

  const removeCustomerTag = () => {
    loadingDelete.value = true
    deleteCustomerTag(customer_tag_id.value).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      loadingDelete.value = false
      isDeleteCustomerTag.value = false
      fetchDataTable()
    })
  }

  watch([searchQuery, options], (newvalue, oldvalue) => {
    loading.value = true
    if (newvalue[0] !== oldvalue[0]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    loadingDelete,
    shop_name,
    isDeleteCustomerTag,
    dataTableList2,
    segmentId,
    footer,
    dataTableList,
    totalDataTableList,
    searchQuery,
    loading,
    options,
    tableColumns,
    totalPage,
    tableColumns2,
    customer_tag_id,
    fetchDataTable,
    removeCustomerTag,
  }
}
