<template>
  <div>
    <v-dialog
      v-model="isAddNewCustomerTag"
      max-width="400"
      persistent
    >
      <v-card>
        <v-form
          ref="formAddCustomerTag"
          @submit.prevent="newCustomerTag"
        >
          <v-card-title>
            {{ $t('add_customer_tags') }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model.trim="shopName"
              readonly
              :label="$t('clinic_name')"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model.trim="customer_tag_name"
              :label="$t('customer_tag')"
              autofocus
              outlined
              :rules="[required]"
              dense
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-add-new-customer-tag', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import customerTag from '@/api/customer/customerTag'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewCustomerTag',
    event: 'update:is-add-new-customer-tag',
  },
  props: {
    shopName: {
      type: String,
      default: '',
    },
    isAddNewCustomerTag: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formAddCustomerTag = ref(null)
    const customer_tag_name = ref('')
    const message = ref('')
    const colorStatus = ref('')
    const statusShowAlert = ref(false)
    const loading = ref(false)
    const { addCustomerTag } = customerTag
    const newCustomerTag = () => {
      const isFormValid = formAddCustomerTag.value.validate()
      if (!isFormValid) return
      loading.value = true
      addCustomerTag(
        {
          customer_tag_name: customer_tag_name.value,
        },
      ).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('update:is-add-new-customer-tag', false)
        emit('onAdd', res.response)
        customer_tag_name.value = ''
      })
    }
    watch(() => props.isAddNewCustomerTag, newValue => {
      if (newValue) {
        customer_tag_name.value = ''
      }
    })

    return {
      formAddCustomerTag,
      loading,
      customer_tag_name,
      message,
      colorStatus,
      statusShowAlert,
      required,
      newCustomerTag,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
